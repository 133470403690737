import React from "react";

import { request } from "./functions";
import * as IS from "./validator";

import loading_img from "./loading.png";
import Popup from "./popup";

const voidf = () => {};
const inputFields = ["text", "ip", "url", "cron", "objectId", "stringa", "string", "email", "textarea", "hidden"];
const numberFields = ["port", "gpio", "statusCode", "number"];

function enter(event) {
    try {
        const div = event.target.parentElement;
        if (div) {
            const span = div.getElementsByClassName("hoverText")[0];
            if (span) {
                const t = div.offsetTop;
                const h = div.offsetHeight;
                const w = div.offsetWidth;
                let st = 0;
                const cont = document.getElementsByClassName("elementContainer")[0];
                if (cont) st = cont.scrollTop;
                span.style.top = `${(t + h - st)}px`;
                span.style.width = `${(w + (w / 2))}px`;
                if (div.parentElement && div.parentElement.scrollLeft) span.style.left = `${(div.parentElement.scrollLeft - ((w / 5) / 2))}px`
                else span.style.left = "";
                span.style.display = "";
            }  
        }            
    } catch(e) {
        console.log(e);
    }     
}
function leave(event) {
    try {
        const div = event.target.parentElement;
        if (div) {
            const span = div.getElementsByClassName("hoverText")[0];
            if (span) span.style.display = "none";
        }
    } catch(e) {
        console.log(e);
    }     
}
export const Hover = (props) => {
    const { hover } = props;
    let ov;
    if (IS.string(hover)) {
        if (hover.includes(" ")) ov = hover.split(" ").map(e => e);
        else ov = [hover];
    }
    if (IS.array(ov)) return (
        <span className="hoverText" style={{ display: "none" }}>
            {ov.map(el => (<>{el}<br/></>))}
        </span>
    )
    else return (
        <span style={{ display: "none" }}></span>
    )
}


/**
 * Display a Boolean Select Field
 * @param {Object} props 
 * @param {string} props.name 
 * @param {*} props.value
 * @param {Function} props.onChange 
 * @param {string} props.label 
 * @param {*} props.style 
 * @param {boolean} props.booleanShow 
 * @param {*} props.spanStyle 
 * @param {*} props.divStyle 
 * @param {boolean} props.disabled 
 * @param {boolean} props.column 
 * @param {boolean} props.cliccable
 * 
 */
export const BooleanField = (props) => {
    const { 
        name, value, onChange, label, style,  
        spanStyle, divStyle, disabled, hover, ...other
    } = props;

    function handleClick(event) {
        if (!disabled) { 
            if (onChange) onChange({ target: { name: name, type: "boolean", value: !value } });
        }
    }

    return (
        <div className="inputContainer inLine" style={divStyle}>
            <div className={value ? "selectBox selected" : "selectBox"} onClick={handleClick} style={style} {...other} />
            <span className="cliccableSpan" style={spanStyle} onClick={handleClick} >
                {label || name}
            </span>
            <input type="hidden" name={name} value={value} />
        </div> 
    ) 
}
/**
 * Display a Generic Input Field
 * @param {Object} props 
 * @param {string} props.name 
 * @param {string} props.type - Default Text
 * @param {*} props.value
 * @param {Function} props.onChange 
 * @param {boolean} props.disabled 
 * @param {*} props.style 
 * @param {boolean} props.inLine 
 * @param {boolean} props.noSpan 
 * @param {string} props.label 
 * @param {*} props.spanStyle 
 * @param {*} props.divStyle 
 * @param {number} props.maxlength
 * @param {string} props.pattern - REGEX Expression
 * @param {number} props.size 
 * @param {string} props.placeholder 
 * 
 */
export const InputField = (props) => {
    const { 
        name, label, type, value, onChange, inLine, lowercase, 
        noSpan, spanStyle, divStyle, size, hover, uppercase, ...other 
    } = props;

    function change(event) {
        if (uppercase && event.target.value) event.target.value = event.target.value.toUpperCase();
        if (lowercase && event.target.value) event.target.value = event.target.value.toLowerCase();
        if (IS.string(type) && typeof IS[type] === "function") {
            if (IS[type](event.target.value)) event.target.style.borderColor = "green";
            else event.target.style.borderColor = "red";            
        }
        if (onChange) onChange({ target: { name: name, value: event.target.value, type: type }});
    }

    let newType = type;
    if (IS.object(value)) newType = "textarea";
    else if (IS.array(value)) newType = "textarea";
    else if (IS.error(value)) newType = "text";
    else if (typeof value === "function") newType = "text";

    let newValue = value;
    if (IS.object(value)) newValue = JSON.stringify(value);
    else if (IS.array(value)) newValue = value.toString();
    else if (IS.error(value)) newValue = value.message;
    else if (typeof value === "function") newValue = "-- function --";

    let dinamicSize = IS.number(size) ? size : 20;
    if (IS.string(newValue) && dinamicSize < (newValue.length + 6)) dinamicSize = newValue.length + 6;

    let common = { name: name, value: newValue, ...other };
    if (IS.string(hover)) common = { ...common, onMouseOver: enter, onMouseOut: leave };

    return (
        <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle} >
            {(!noSpan) && (<span style={spanStyle}>{label || name}</span>)}
            {IS.string(hover) && (<Hover hover={hover} />)}
            {(IS.string(newType) && newType === "textarea") ? (
                <textarea onChange={onChange || voidf} {...common} />
            ) : (
                <input type={newType} onChange={change} size={dinamicSize} {...common} />
            )}
        </div>  
    )
}
/**
 * Display a Dropdown Select Field
 * @param {Object} props 
 * @param {string} props.name 
 * @param {*} props.value
 * @param {Function} props.onChange 
 * @param {*} props.options 
 * @param {*} props.style  
 * @param {boolean} props.inLine 
 * @param {string} props.label 
 * @param {string} props.type
 * @param {boolean} props.noVoid 
 * @param {*} props.spanStyle 
 * @param {*} props.divStyle 
 * @param {boolean} props.disabled 
 * @param {boolean} props.noSpan 
 * 
 */
export const SelectField = (props) => {
    const { 
        name, label, type, value, onChange, options, inLine, 
        divStyle, disabled, noSpan, noVoid, hover, ...other
    } = props;

    function change(event) {
        if (!disabled) {
            let newValue = event.target.value; 
            if (IS.string(type) && type === "number") newValue = Number(newValue);
            if (onChange) onChange({ target: { value: newValue, name: event.target.name, type: type } });
        }
    }

    let opts = [];
    if (IS.array(options)) {
        options.filter(e => !IS.object(e)).forEach((el) => {
            opts.push({ value: el, label: el })
        })
    } else if (IS.object(options)) {
        Object.entries(options).forEach(([key, val]) => {
            opts.push({ value: val, label: key })
        })
    } 

    let dis = disabled;
    if (opts.length === 0) dis = true;
    if (!noVoid) opts.unshift({ value: "", label: "" });

    let common = { ...other };
    if (IS.string(hover)) common = { ...common, onMouseOver: enter, onMouseOut: leave };

    return (
        <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
            {!noSpan && (<span>{label || name}</span>)}
            {IS.string(hover) && (<Hover hover={hover} />)}
            <select name={name} value={value} onChange={change} disabled={dis} {...common}>               
                {opts.map((opt, ind) => (
                    <option key={`select${name}.option${ind}`} value={opt.value}>{opt.label}</option>
                ))}                
            </select>
        </div> 
    )
}

export const NumberField = (props) => {
    const { 
        name, value, type, onChange, label, spanStyle, divStyle, 
        disabled, inputClass, inLine, noSpan, noButton, hover, ...other
    } = props; 
    
    function handleSub(event) {
        try {
            if (!disabled) {
                let inputField = event.target.parentElement.getElementsByTagName("input")[0].cloneNode(true);
                inputField.value = Number(inputField.value) - Number(inputField.step || 1);
                if (IS.string(inputField.step) && inputField.step.includes(".")) {
                    let dec = inputField.step.split(".")[1];
                    inputField.value = Number(inputField.value).toFixed(dec.length);
                }
                if (onChange) change({ target: inputField });
            }    
        } catch(e) {
            console.log(e);
        }      
    }
    function handleAdd(event) {
        try {
            if (!disabled) {
                let inputField = event.target.parentElement.getElementsByTagName("input")[0].cloneNode(true);
                inputField.value = Number(inputField.value) + Number(inputField.step || 1);
                if (IS.string(inputField.step) && inputField.step.includes(".")) {
                    let dec = inputField.step.split(".")[1];
                    inputField.value = Number(inputField.value).toFixed(dec.length);
                }
                if (onChange) change({ target: inputField });
            }    
        } catch(e) {
            console.log(e);
        }  
    }
    function change(event) {
        if (!disabled) {  
            let newValue = Number(event.target.value); 
            if (event.target.max && (newValue > Number(event.target.max))) newValue = Number(event.target.max);
            if (event.target.min && (newValue < Number(event.target.min))) newValue = Number(event.target.min);
            if (onChange) onChange({ target: { value: newValue, name: name, type: type } });
        }
    }

    let params = {
        type: "number",
        name: name
    }
    if (IS.string(type) && type === "gpio") {
        params.min = 0;
        params.max = 27;
    }

    let common = { };
    if (IS.string(hover)) common = { ...common, onMouseOver: enter, onMouseOut: leave };

    return (
        <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
            {!noSpan && (<span style={spanStyle}>{label || name}</span>)}
            {IS.string(hover) && (<Hover hover={hover} />)}
            <div className="numberInputContainer" {...common} >
                {!noButton && (<button onClick={handleSub}>-</button>)}
                <input  
                    value={value} 
                    onChange={change}
                    disabled={disabled}
                    {...params}
                    {...other}
                />
                {!noButton && (<button onClick={handleAdd}>+</button>)}
            </div>
        </div> 
    )
}

export const TimeField = (props) => {
    const { 
        name, value, onChange, disabled, style, inLine, hover, noSpan, 
        label, spanStyle, divStyle, noTime, noDate, seconds, ...other 
    } = props;

    let dateObj = new Date(Date.parse(value));
    if (isNaN(Date.parse(value))) dateObj = new Date(Date.now());
    let date = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, "0")}-${String(dateObj.getDate()).padStart(2, "0")}`;
    let time = `${String(dateObj.getHours()).padStart(2, "0")}:${String(dateObj.getMinutes()).padStart(2, "0")}`;
    if (seconds) time += `:${String(dateObj.getSeconds()).padStart(2, "0")}`;

    function change(event) {
        if (!disabled && onChange) {
            if (event.target.type === "time") time = event.target.value;            
            else if (event.target.type === "date") date = event.target.value;
            if (!seconds) time += `:00`;
            onChange({ target: { name: name, value: new Date(`${date}T${time}`).toISOString() }})
        }
    }

    let common = { };
    if (IS.string(hover)) common = { ...common, onMouseOver: enter, onMouseOut: leave };

    return (
        <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
            {((!disabled || label) && !noSpan) && (<span style={spanStyle}>{label || name}</span>)}
            {IS.string(hover) && (<Hover hover={hover} />)}
            <div className="timeInputs" {...common} >
                {!noDate && (
                    <input 
                        type="date"
                        value={date}
                        onChange={change}
                        readOnly={disabled}
                        style={{ ...style, marginRight: "0.3em" }}
                        {...other}
                    />
                )}
                {!noTime && (
                    <input 
                        type="time"
                        value={time}
                        onChange={change}
                        readOnly={disabled}
                        style={style}
                        {...other}
                    />
                )}
            </div>            
        </div>  
    )
}

export const FileField = (props) => {
    const { 
        name, label, accept, onChange, hover, id, 
        divStyle, disabled, button, onClick, ...other
    } = props;

    function click(event) {
        const input = event.target.parentElement.getElementsByTagName("input")[0];
        if (!disabled && input) input.click();
    }
    function change(event) {
        if (!disabled && onChange) {
            if (event.target.files[0]) {
                if (accept && (accept === ".json")) {
                    try {                            
                        const reader = new FileReader();
                        reader.onload = (evt) => {
                            const json = JSON.parse(evt.target.result);
                            onChange({ target: { name: name, value: json }});
                        }                    
                        reader.readAsText(event.target.files[0]); 
                    } catch (err) {
                        console.log(err);
                    }
                } else {
                    try {                        
                        const url = URL.createObjectURL(event.target.files[0]);
                        onChange({ target: { name: name, value: url }});
                    } catch (err) {
                        console.log(err);                        
                    }
                }                   
            }
        }
    }
    function clickBtn(event) {
        if (!disabled && onClick) {
            const inputField = event.target.parentElement.getElementsByTagName("input")[0];
            if (inputField.files.length > 0) onClick({ target: { name: name, files: inputField.files }});
        }
    }

    let common = { ...other };
    if (IS.string(hover)) common = { ...common, onMouseOver: enter, onMouseOut: leave };

    return (
        <div className="inputContainer inLine" style={divStyle || { justifyContent: "center" }}>
            <input type="file" accept={accept} style={{ display: "none" }} onChange={change} id={id} />
            {IS.string(hover) && (<Hover hover={hover} />)}
            <button className="fileButton" onClick={click} {...common}>{label || accept}</button>
            {button && (<button onClick={clickBtn}>{button}</button>)}
        </div>  
    )
}

export const Button = (props) => {
    const { 
        name, label, value, onClick, onChange, hover, className,
        divStyle, style, disabled, image, noBounce, selected, ...other
    } = props;

    function click(event) {
        if (onClick || onChange) {
            const div = event.target.parentElement;
            if (div) {
                const span = div.getElementsByClassName("hoverText")[0];
                if (span) span.style.display = "none";
            }
            if (!noBounce) {
                event.target.classList.add("bounceButton");
                event.target.addEventListener("animationend", () => { event.target.classList.remove("bounceButton") });
            }
            if (onClick) onClick(event);            
            if (onChange && name) {
                let ev = { target: { name: name, type: typeof value }};
                if (IS.boolean(value)) ev.target.value = !value;
                else ev.target.value = value;                
                onChange(ev);
            }
        }        
    }

    let disp = "-";
    if (IS.string(label)) disp = label;
    else if (IS.string(name)) disp = name;
    else if (typeof value !== "undefined") disp = `${value}`;

    let realStyle = {};
    if (IS.object(style)) realStyle = { ...realStyle, ...style };
    if (selected) realStyle.background = "var(--btColorFull)";

    let common = { style: realStyle };    
    if (IS.string(hover)) common = { ...common, onMouseOver: enter, onMouseOut: leave };

    return (
        <div className="inputContainer" style={divStyle}>
            {IS.string(hover) && (<Hover hover={hover} />)}
            <button onClick={click} value={value} disabled={disabled} className={className ? className : ""} {...common} >
                {image ? (<img src={image} alt="btn" />) : (<>{disp}</>)}
            </button>
        </div>
    )
}

class NewPassword extends React.Component {
    state = {
        password: "",
        repeat: "",
        strength: {
            length: false,
            uppercase: false,
            lowercase: false,
            special: false,
            number: false
        },
        params: {
            length: 8,
            uppercase: false,
            lowercase: false,
            special: false,
            number: false
        },
        texts: {
            length: `Lunghezza almeno @val@ caratteri`,
            uppercase: `Almeno una lettera Maiuscola`,
            lowercase: `Almeno una lettera Minuscola`,
            special: `Almeno un carattere Speciale`,
            number: `Almeno un Numero`,
        },
        label: "NEW PASSWORD",
        disabled: true,
        loading: false
    }

    constructor(props) {
        super(props);
        if (props.label) this.state.label = props.label;
    }

    componentDidMount() {
        this.loadItems();
    }

    loadItems = async () => {
        try {
            this.setState({ loading: true });
            const r = await request("login/getPasswordStrength");
            if (r.statusCode === 200) {
                let ob = {};
                for (const [k, v] of Object.entries(r.body)) {
                    if (IS.number(v)) ob[k] = false;
                    else if (IS.boolean(v) && v === true) ob[k] = false;
                }
                this.setState({ params: r.body, strength: ob });
            } else Popup(r);
            this.setState({ loading: false });
        } catch(e) {
            Popup(e);
            this.setState({ loading: false });
        }
    }
    
    handleNew = (event) => {
        const { value } = event.target;
        const { onChange, name } = this.props;
        const { params } = this.state;
        if (IS.object(params)) {
            let s = {};
            if (IS.number(params.length)) s.length = value.length >= params.length;
            if (IS.boolean(params.uppercase) && params.uppercase === true) s.uppercase = /[A-Z]/.test(value);
            if (IS.boolean(params.lowercase) && params.lowercase === true) s.lowercase = /[a-z]/.test(value);
            if (IS.boolean(params.number) && params.number === true) s.number = /[0-9]/.test(value);
            if (IS.boolean(params.special) && params.special === true) s.special = /[^A-Za-z0-9]/.test(value);
            let ok = true;
            for (const v of Object.values(s)) {
                if (v === false) {
                    ok = false;
                    break;
                }
            }
            if (ok === true) {
                this.setState({ disabled: false });
                event.target.style.borderColor = "green";
            } else {
                this.setState({ disabled: true, repeat: "" });
                if (onChange) onChange({ target: { name: name, value: "" }});
                event.target.style.borderColor = "red";
            }
            this.setState({ [event.target.name]: value, strength: s });
        }
    }

    handleRepeat = (event) => {
        const { value } = event.target;
        const { password } = this.state;
        const { onChange, name } = this.props;
        this.setState({ [event.target.name]: value });
        if (value === password) {
            event.target.style.borderColor = "green";
            if (onChange) onChange({ target: { name: name, value: password }});
        } else {
            event.target.style.borderColor = "red";
            if (onChange) onChange({ target: { name: name, value: "" }});
        }        
    }

    render() {
        const { password, repeat, strength, label, disabled, params, texts, loading } = this.state;
        const { inLine, placeholder, size, divStyle } = this.props;

        let dinamicSize = IS.number(size) ? size : 20;
        if (IS.string(password) && dinamicSize < (password.length + 2)) dinamicSize = password.length + 2;

        if (loading) return (
            <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
                {!inLine && (<span>{label}</span>)} 
                <img src={loading_img} alt="loading" className="spin" /> 
            </div>
        )
        else return (
            <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
                {!inLine && (<span>{label}</span>)} 
                <input 
                    type="password" 
                    value={password} 
                    name="password" 
                    onChange={this.handleNew} 
                    placeholder={placeholder}
                    size={dinamicSize}
                    disabled={this.props.disabled}
                />        
                <div className="passwordStrength">
                    {(IS.object(strength) && Object.keys(strength).length) && (
                        <React.Fragment>
                            {Object.entries(strength).map(([key, val]) => (
                                <span key={`strength.${key}`} className={val ? "passed" : ""}>
                                    {IS.string(texts[key]) ? (
                                        <React.Fragment>
                                            {texts[key].includes("@val@") ? texts[key].replaceAll("@val@", `${params[key]}`) : texts[key] }
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {`Require at least one ${key} char`}
                                        </React.Fragment>                                        
                                    )}
                                </span>
                            ))}
                        </React.Fragment>
                    )}
                </div>  
                <input 
                    type="password" 
                    value={repeat} 
                    name="repeat" 
                    onChange={this.handleRepeat} 
                    disabled={disabled} 
                    placeholder={disabled ? "... Password ..." : "... Ripeti ..."}
                    size={dinamicSize}
                />
            </div>
        )
    }
}
export { NewPassword };

export const Field = (props) => {
    const { field, options, ...common } = props;

    if (IS.array(options) || IS.object(options)) return (
        <SelectField options={options} {...common} />
    ) 
    else if (IS.object(field) && (IS.array(field.options) || IS.object(field.options))) return (
        <SelectField {...common} {...field} />
    )
    else {
        let params = { type: "undefined" };
        if (IS.object(field)) params = Object.assign({}, field);
        if (IS.string(field)) params.type = field;
        else if (IS.object(field) && IS.string(field.type)) params.type = field.type;
        else if (typeof field !== "undefined") params.type = typeof field;
        else if (IS.string(props.type)) params.type = props.type;
        else params.type = typeof props.value;
        if (inputFields.includes(params.type)) return (
            <InputField {...common} {...params} />
        )
        else if (numberFields.includes(params.type)) return (
            <NumberField {...common} {...params} />
        )
        else if (params.type === "button") return (
            <Button {...common} {...params} />
        )
        else if (params.type === "file") return (
            <FileField {...common} {...params} />
        )
        else if (params.type === "boolean") return (
            <BooleanField {...common} {...params} />
        )
        else if (params.type === "time") return (
            <TimeField {...common} {...params} />
        )
        else if (params.type === "newPassword") return (
            <NewPassword {...common} {...params} />
        )
        else return (
            <InputField {...common} {...params} />
        )          
    }
}

export const Loading = (props) => {
    const { className, img, style } = props;

    if (img) return (
        <img src={loading_img} alt="loading" className="spin" style={style} />        
    )
    else if (className) return (
        <div className={className}>
            <div className="loadingContainer" >
                <img src={loading_img} alt="loading" className="spin" style={style} />
            </div>
        </div>
    )
    else return (
        <div className="loadingContainer" >
            <img src={loading_img} alt="loading" className="spin" style={style} />
        </div>
    )
}

export function getVal(obj, path) {
    try {
        const value = path.replace(/\[([^\[\]]*)\]/g, '.$1.').split('.').filter(t => t !== '').reduce((prev, cur) => prev && prev[cur], obj)
        return value;
    } catch(e) {
        return e.message;
    }
}

export function setProperty(obj, path, value) {
    const [head, ...rest] = path.split('.');
    return {
        ...obj,
        [head]: rest.length
            ? setProperty(obj[head], rest.join('.'), value)
            : value
    }
}

export function handleChange(event, state) {
    const { name, value, type } = event.target;
    let newValue = value;
    if (type === "number") newValue = Number(value);  
    else if ((type === "hidden")&&(value === "true")) newValue = true;
    else if ((type === "hidden")&&(value === "false")) newValue = false;
    if (name.includes(".")) {
        const parentPath = name.substring(0, name.indexOf("."));
        const childPath = name.substring(name.indexOf(".") + 1);
        return ({ [parentPath]: setProperty(state[parentPath], childPath, newValue) }); 
    } else return ({ [name]: newValue });               
}   

export const Load = (props) => {
    const { title, text, more, style, noKeys } = props;
    return (
        <div className="loaderContainer" style={style}>
            {IS.string(title) ? (
                <React.Fragment>
                    <h1>{title}</h1>
                    {IS.string(text) && (<h3>{text}</h3>)}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <img src={loading_img} className="spin" alt="loading" />
                    <h3>{IS.string(text) ? text : `... Loading ...`}</h3>
                </React.Fragment>
            )}
            {IS.string(more) && (<h3>{more}</h3>)}
            {!noKeys && (
                <ul>
                    <li>ESC entra/esci full screen</li>
                    <li>END Aggiorna Slide</li>                                
                </ul>
            )}
        </div>
    )
}
/**
 * Display an hover div with message
 * @param {*} text - Text to display, String, Error or Object with Object.result as String
 * @param {number} time - ms before closing div, default 3000 ms
 */
export function HoverDiv(text) { 
    try {
        let timeout;
        let time = 3000;
        let remove = true;
        let txt = "";
        const randomId = `hoverDiv-${Math.floor(Math.random() * 5000)}`;    
        if (arguments.length > 1) {
            for (let i=1; i<arguments.length; i++) {
                if (IS.number(arguments[i])) time = arguments[i];
                else if (IS.string(arguments[i]) && arguments[i] === "noRemove") remove = false;
            }
        }    
        function close() {
            if (document.getElementById(randomId)) document.getElementById(randomId).remove(); 
            if (timeout) clearTimeout(timeout);
        }  
        if (IS.string(text)) txt = text;
        else if (IS.error(text)) txt = text.message;
        else if (IS.object(text) && IS.string(text.result)) txt = text.result;
        else if (IS.object(text) && IS.string(text.message)) txt = text.message;
        const div = createEl(txt, { class: "hoverDiv", id: randomId }, "div", close);
        if (document.getElementById("hovers")) document.getElementById("hovers").appendChild(div);
        else document.body.appendChild(div);        
        if (remove === true) timeout = setTimeout(() => { close() }, time);
    } catch(e) {
        console.log(e);
    }
}
/**
 * Create an HTML Element, Accepted Optional params are:
 * - String: the HTML TAG name, by default is SPAN
 * - Object: Optional element values
 * - Function: If given set the onClick function event to Element
 * - Array: If given set the arguments for onClick function
 * @param {*} con - Content to display, String, Error or Object with Object.result as String
 * @returns HTML Element
 */
export function createEl(con) {
    try {
        let tag = "span";
        let params;
        let text;
        let func;
        let args = [];
        if (IS.string(con)) text = con;
        else if (IS.error(con)) text = con.message;
        else if (IS.object(con) && IS.string(con.result)) text = con.result;
        else text = `${con}`;
        if (arguments.length > 1) {
            for (let i=1; i<arguments.length; i++) {
                if (IS.string(arguments[i])) tag = arguments[i];
                else if (IS.object(arguments[i])) params = arguments[i];
                else if (IS.func(arguments[i])) func = arguments[i];
                else if (IS.array(arguments[i])) args = arguments[i];
            }
        }
        const elem = document.createElement(tag);
        elem.innerHTML = text;
        if (IS.object(params)) {
            for (const [k, v] of Object.entries(params)) {                
                if (IS.string(v)) elem.setAttribute(k, v);
            }
        }
        if (IS.func(func)) elem.onclick = () => { func(...args) };
        return elem;
    } catch(e) {
        console.log(e);
        const span = document.createElement("span");
        span.innerHTML = e.message;
        return span;
    }
}

export const HoverImage = (props) => {
    const { src, alt, onClick, hover, ...other } = props;

    function enter(event) {
        try {
            const span = event.target.nextElementSibling;
            if (span) {
                const rect = event.target.getBoundingClientRect();
                span.style.top = `${(rect.top + rect.height + 5 - window.scrollY)}px`;
                span.style.left = `${((rect.left - (rect.width / 2)) - window.scrollX)}px`;
                span.style.width = `${(rect.width + (rect.width / 2))}px`;
                span.style.display = "";
            }                   
        } catch(e) {
            console.log(e);
        }     
    }

    function leave(event) {
        try {
            const span = event.target.nextElementSibling;
            if (span) span.style.display = "none";        
        } catch(e) {
            console.log(e);
        }     
    }

    let common = { ...other };
    if (IS.string(hover)) common = { ...common, onMouseOver: enter, onMouseLeave: leave };
    if (IS.func(onClick)) common = { ...common, onClick: onClick, className: "clickImage" };

    return (
        <React.Fragment>
            <img 
                src={IS.string(src) ? src : "/img/not_found.png"} 
                alt={IS.string(alt) ? alt : "img"}
                {...common}
            />
            {IS.string(hover) && (
                <span className="hoverText" style={{ display: "none"}}>{hover}</span>
            )}
        </React.Fragment>
    )
}

export const fileAccepted = ".pdf, .doc, .docx, .xls, .xlsx, .csv, .ppt, .pptx, .p7m, image/*, video/*, audio/*";

export const webExts = [
    "csv", "doc", "docx", "eml", "flv", "gif", "jpeg", 
    "jpg", "mov", "mp3", "mp4", "pdf", "png", "ppt", 
    "pptx", "rar", "txt", "wav", "xls", "xlsx", "zip"
];
