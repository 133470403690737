/**
 * FUNCTIONS TO CHECK VARIOUS TYPE OF FIELDS
 * All Functions return boolean value
 */
export function array(arr, notEmpty) {
    try {
        if (Array.isArray(arr)) {
            if (typeof notEmpty === "boolean" && notEmpty === true) {
                if (arr.length) return true;
                else return false;
            } else return true;
        } else return false;
    } catch(e) {
        return false;
    }
}

export function object(obj, notEmpty) {
    try {
        if (obj && typeof obj === "object") {
            if (obj instanceof Date) return false;
            else if (obj instanceof Event) return false;
            else if (obj instanceof Error) return false;
            else if (Array.isArray(obj)) return false;
            else if (obj instanceof Object) {
                if (typeof notEmpty === "boolean" && notEmpty === true) {
                    if (Object.keys(obj).length) return true;
                    else return false;
                } else return true;
            } else return false;
        } else return false;
    } catch(e) {
        return false;
    }
}

export function string(string, empty) {
    try {        
        if (typeof string === "string") {
            if (typeof empty === "boolean" && empty === true) return true;
            else if (string.length) return true;
            else return false;
        } else return false;
    } catch(e) {
        return false;
    }
}

export function evento(string) {
    if (!string) return true;
    else if (typeof string === "string") return true;
    else return false;
}

export function url(string) {
    try {
        if (string && (typeof string === "string")) {
            const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
            '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
            return !!urlPattern.test(string);
        } else return false;
    } catch(e) {
        return false;
    }
}

export function ip(string) {  
    try {
        if (!string || (typeof string !== "string")) return false;
        else return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(string); 
    } catch(e) {
        return false;
    }
}

export function boolean(val) {
    try {
        if (typeof val === "boolean") return true;
        else if ((typeof val === "string") && val && ((val.toLowerCase() === "true")||(val.toLowerCase() === "false"))) return true;
        else return false;
    } catch(e) {
        return false;
    }
}

export function statusCode(num) {
    try {
        if (!num || (typeof num !== "number")) return false;
        else if (!Number.isInteger(num)) return false;
        else if ((num < 200)||(num > 599)) return false;
        else return true;
    } catch(e) {
        return false;
    }
}

export function error(obj) {
    try {
        if (obj instanceof Error) return true;
        else if (obj && (typeof obj === "object") && obj.message && obj.stack) return true;
        else return false;
    } catch(e) {
        return false;
    }
}

export function email(string) {
    try {
        if (!string || (typeof string !== "string")) return false ;
        else {
            const splitted = string.split("@");
            if (splitted && (splitted.length === 2)) {
                if (splitted[1].includes(".")) return  true;
                else return false;
            } else return false;
        }
    } catch(e) {
        return false;
    }
}

export function gpio(num) {
    try {
        if (!num || (typeof num !== "number")) return(false);
        else if (!Number.isInteger(num)) return(false);
        else if ((num < 0)||(num > 27)) return(false);
        else return(true);
    } catch(e) {
        return(false);
    }
}

export function port(num) {
    try {
        if (!num || (typeof num !== "number")) return(false);
        else if (!Number.isInteger(num)) return(false);
        else if ((num < 1)||(num > 65535)) return(false);
        else return(true);
    } catch(e) {
        return(false);
    }
}

export function objectId(string) {
    try {
        if (!string || (typeof string !== "string")) return false;
        else return /^[a-fA-F0-9]{24}$/.test(string);
    } catch(e) {
        return(false);
    }
}

export function number(num) {
    if (typeof num === "number") return true;
    else return false;
}

export function cron(string) {
    try {
        if (!string || (typeof string !== "string")) return false;
        else return /^(\*|[0-9\-,]*)\s(\*|[0-9\-,]*)\s(\*|[0-9\-,]*)\s(\*|[0-9\-,]*)\s(\*|[0-9\-,]*)$/.test(string);
    } catch(e) {
        return false;
    }
}

export function date(el) {
    try {
        if (el instanceof Date) return true;
        else if (!el || typeof el !== "string") return false;
        else return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(el);  
    } catch(e) {
        return false;
    }
}


export function event(obj) {
    try {
        if (obj instanceof Event) return true;
        else if (obj && (typeof obj === "object") && obj.target && (typeof obj.target === "object")) return true;
        else return false;
    } catch(e) {
        return false;
    }
}

export function formData(obj) {
    if (obj && (obj instanceof FormData)) return true;
    else return false;
}

export function func(el) {
    if (typeof el === "function") return true;
    else return false;
}

export function objectUrl(el) {
    try {
        if (!el || typeof el !== "string") return false;
        else return /^(blob|data?):(http|https?):\/\/[^ "]+$/.test(el);
    } catch(e) {
        return false;
    }    
}